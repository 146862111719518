// SingleEvent.js
import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function SingleEvent10() {

    const [heroSectionEventData] = useState({
        image: "images/Barrie-Concerts/2024-2025/bc_29_03_25.jpg",
        title: 'Things Lived and Dreamt',
        subtitle: 'Francine Kay',
        date: 'Saturday 29 March 2025 7.30pm',
        location: 'Hiway Church, 50 Anne Street North, Barrie',
        buttonText: 'Book Tickets',
        buttonLink: 'https://www.showpass.com/francine-kay-pianist/',
    });

    const [eventData] = useState({
        program: `Join us for an inspiring program of Romantic and Impressionist works by Debussy, Dvorak, Chopin, and others.  Please click on the tab below for the program.`,

performers: `Noted for “an extraordinary range of colour” (Montreal Gazette), and “poetic brilliance” (Toronto Star), Canadian pianist Francine Kay has performed extensively in Europe, North America, and Asia, at venues such as Carnegie Hall, Weill Recital Hall, Lincoln Center, Salle Gaveau, The National Gallery, Roy Thomson Hall, The Dame Myra Hess Memorial Concerts, and Bargemusic. Francine Kay made her New York debut at Carnegie Hall’s Weill Recital Hall as the winner and Recitalist of the Year of the New York Pro Piano Competition.

She has been soloist with orchestras such as the Toronto Symphony, the Princeton Symphony, New York’s West Side Chamber Orchestra, the Manitoba Chamber Orchestra, Orchestra London, the Kitchener-Waterloo Symphony, Symphony Nova Scotia, the Victoria Symphony and Sinfonia Toronto among others, under conductors such as Georg Tintner, Mark Laycock, Nurhan Arman, Agnes Grossman, Kevin Mallon, Jonathan Yates, and Simon Streatfeild. 

Francine Kay’s recordings on the Analekta and Audio Ideas labels have received international acclaim. Her recording of the Debussy Preludes was nominated for a JUNO Award and selected by Germany’s Fono Forum as as Disc of the Month, citing its “astonishing grace and floating sonorities”. Francine Kay gave the premiere of Canadian composer Oskar Morawetz’s Four Contrasting Moods which became a live recording for CBC records.  Ms. Kay’s performances have been broadcast on NPR, the BBC, WFMT, WCNY, Radio France, and the CBC, among others.`,

        performerLink: 'https://www.francinekay.com/',
        ticketInfo: `This concert unfortunately had to be cancelled at the last minute due to a power outage at the venue.  You will be informed of a new date as soon as we are able to arrange that.`,
        pdfPath: `pdf/2024-2025/bc_29_03_25.pdf`
    });

    const barrieConcert = [
        {
            title: 'Duo Turgeon',
            description: 'From Bruce with Love',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_05-10-24.jpg',
            link: '/SingleEvent6'
        },
        {
            title: 'Dévah Quartet',
            description: 'Dévah Quartet',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_30-11-24.jpg',
            link: '/SingleEvent7'
        },
        {
            title: 'True North Brass',
            description: 'Dancing Across the Global',
            imageUrl: 'images/Barrie-Concerts/2024-2025/bc_18_01_25.jpg',
            link: '/SingleEvent8'
        },
    ];
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}  
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink}
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
                pdfPath={eventData.pdfPath}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Barrie Concerts" events={barrieConcert} buttonLink="/BarrieConcert" />
            </Box>
        </Box>
    );
}

export default SingleEvent10;
